body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #444;
}

/* ==========================================================================
navbar text
========================================================================== */
.navbar-default .navbar-brand {
    color: #444;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 1px 1px;
}

.logo .logo-img {
    height: 50px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a {
    color: #1c0cf5;
}

a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}




/* jhipster-needle-css-add-main JHipster will add new css style */



/*full body layout css*/

.fullBody{
    background: #fff9ea;
}

/* header-head CSS*/
.topHeaderBg{
    background: #9D0552;
}

.fa-facebook{
    color: #FFFFFF;
    background: #3C599F;
}

.fa-instagram{
    color: #fff;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.fa-twitter{
    background: #32CCFE;
    color: rgba(255, 255, 255, 0.8);
}

.fa-linkedin{
    background: #0085AE;
    color: rgba(255, 255, 255, 0.8);
}

@media (min-width:1401px) {
    .header-head{
        min-height: 200px;
        background: linear-gradient(to right, #ffffff, #F2F2F2, #ffffff, #f7f5f5 );
    }
    .logo-img {
        height: 165px;
        display: inline-block;
    }
    .logo-img1 {
        height: 160px;
        display: inline-block;
    }
    .logo-img2 {
        height: 85px;
        display: inline-block;
    }
    .textColor{
        color: #fff;
        font-size: 10px;
    }

    .fa .fa-facebook{
        width:30px;
        height:30px;
    }
}
@media (max-width:1400px) {
    .header-head{
        min-height: 200px;
        background: linear-gradient(to right, #ffffff, #F2F2F2, #ffffff, #f7f5f5 );
    }
    .logo-img {
        height: 165px;
        display: inline-block;
    }
    .logo-img1 {
        height: 160px;
        display: inline-block;
    }
    .logo-img2 {
        height: 85px;
        display: inline-block;
    }
    .textColor{
        color: #fff;
        font-size: 14px;
    }
    .fa .fa-facebook{
        width:25px;
        height:250px;
    }
}
@media (max-width:900px) {
    .header-head{
        min-height: 200px;
        background: linear-gradient(to right, #ffffff, #F2F2F2, #ffffff, #f7f5f5 );
    }
    .logo-img {
        height: 145px;
        display: inline-block;
    }
    .logo-img1 {
        height: 140px;
        display: inline-block;
    }
    .logo-img2 {
        height: 85px;
        display: inline-block;
    }
    .textColor{
        color: #fff;
        font-size: 12px;
    }
}
@media (max-width:426px) {
    .header-head{
        min-height: 120px;
        background: linear-gradient(to right, #ffffff, #F2F2F2, #ffffff, #f7f5f5 );
    }
    .logo-img {
        height: 65px;
        display: inline-block;
    }
    .logo-img1 {
        height: 90px;
        display: inline-block;
    }
    .logo-img2 {
        height: 85px;
        display: inline-block;
    }
    .textColor{
        color: #fff;
        font-size: 8px;
    }
}
ul{
    list-style-type: none;
}



/*.header-brand .logo {
    padding-left: 10px;
    padding-top: 10px;
}*/


.paddingRemove1{
    padding-top: 18px;
}

.topHeaderHover > a:focus,
.topHeaderHover > a:hover{
color: #ecb731;
}
.navbar-default {
  background-color:teal;
  background-image: none;
  background-repeat: no-repeat;
}

/*top navbar main CSS */

.navbar-collapse{
    margin-top: -20px;
    background:#9D0552;
    -webkit-border-radius: 10px;
    -moz-border-radius: 0px;
    border-radius: 10px;
}

.navbar{
    min-height: 30px;
    border: 0px;
    background: #fff9ea;
}

.navbar-header{
    margin-top: -20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.navbar-default .navbar-nav > li > a:hover{
    color: #ecb731;
}

.navbar-nav > li > a {
    padding-top: 5px;
    padding-bottom:5px;
    line-height:20px;
}

.navbar-default .navbar-nav > li > a{
    color: #fff;
    font-size: 12px;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus{
    color:#efc459;
    background: none;
}
/*dropdown nav Bar CSS*/
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #ecb731;
    background: none;
}

.dropdown-menu > li > a {
    color: #c54a4a;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: #9D0552;
}


/* footer css */
footer {
    background-color: rgba(44, 62, 80, 1.0);
    width: 100%;
    bottom: 0;
    font-weight: lighter;
    color: white;
}
.footerHeader{
    width:100%;
    padding:1em;
    background-color:rgba(52, 73, 94,1.0);
    text-align:center;
    color:white;
}
footer h3{
    font-weight:lighter;
}
footer ul{
    padding-left:5px;
    list-style:none;
}
footer p{
    text-align : justify;
    font-size : 12px;
}
footer iframe {
    width:100%;
    position:relative;
    height:180px;
}
footer a {
	color: white;
}
footer a:hover {
	color: #ecb731;
}

/* news panel css */
.bodysize{
    height:247px;
    overflow:hidden;
}
.news_wrap span {
   background: #bfd9df;
   padding: 5px;
   font-weight: bold;
   color: #333;
   margin-bottom: 6px;
   display: inline-block;
}

/* iframe contact us */
.iframemap {
    width:100%;
    position:relative;
    height:330px;
}

linkcolor{
    color: white;
}

/* instruction height */
.instmodal{
    height:450px;
    overflow:scroll;
}
.imgMargin{
  margin-bottom: 20px;
}

.selectCheckbox{
    position:absolute;
    width:30px;
    height:27px;
}

#deleteButton{
    position:absolute;
    margin-left: 294px;
    margin-top: -6px;
    padding-top: 3px;
    padding-left: 10px;
    width:30px;
    height:27px;
}

.bg-clr{
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}
.closeBtn:hover, .closeBtn:focus{
    color: white;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5;
}

.mouseChange{
cursor: pointer;
}

.album {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.88);
    color: #f1f1f1;
    width: 315px;
    height: 41px;
    padding: 8px;
}
.interviewAlbum {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.88);
    color: #f1f1f1;
    width: 315px;
    height: 49px;
    padding: 1px 10px 1px 10px;
}





